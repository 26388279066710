<template>
  <div class="main" >
    <a-row >
      <a-col :lg="10" span="24">
        <div class="home_text" style="padding-left:42px; padding-right: 22px;">
          <div class="title">
            <h2>Vllshop云手机<br>助力<span class="org">业务出海无忧</span></h2>
            <p>
              一人批量管控N台云手机，多海外平台推广，<br>让业务遍布全球。
            </p>
            <a-button round style="margin-top: 20px;width: 184px;height: 50px;font-weight: bolder;border-radius: 50px;font-size: 18px" @click="yunLogin()">
              立刻体验
            </a-button>
          </div>
        </div>
      </a-col>
      <a-col :lg="14" span="24" style="overflow: hidden;background-color:#00307D;display:flex;justify-content: center">
        <img src="/images/yun.png">
      </a-col>
    </a-row>
    <!--scenes-->
    <div class="out-box">
      <div class="scenes-box">
        <div class="title">
          场景支持
        </div>

        <ul class="scenes-list">
          <a-row type="flex" justify="center" :gutter="[24,24]">
            <a-col :md="12"  :span="22" v-for="(item,index) in scenes" :key="index">
              <li >
                <div class="scenes-img">
                  <img :src="item.img">
                </div>
                <div class="text">
                  <div class="scenes-title">
                    {{ item.title }}
                  </div>
                  <div>{{ item.p }}</div>
                </div>
              </li>
            </a-col>
          </a-row>

        </ul>
      </div>
    </div>
    <!--scenes-->
    <!--adv-box-->
    <div class="out-box">
      <div class="adv-box">
        <div class="title">
          云手机优势
        </div>
        <ul class="adv-list">
          <a-row :gutter="[24,24]" type="flex" justify="center">
            <a-col :md="6" :span="22"  v-for="(item,index) in adv" :key="index">
              <li style="overflow: hidden">
                <img :src="item.img">
                <div class="text">
                  <div class="adv-title">
                    {{ item.title }}
                  </div>
                  <div> {{ item.p }} </div>
                </div>
              </li>
            </a-col>
          </a-row>

        </ul>
        <button class="exp-btn" @click="yunLogin()">
          马上体验
        </button>
      </div>
    </div>
    <!--adv-box-->
    <!--app-box-->
    <div class="out-box">
      <div class="title">
        全面支持各大主流平台应用
      </div>
      <div class="subtitle">
        广告营销、短视频、直播带货变现
      </div>
      <div class="app-list" style="overflow: hidden">
        <div>
          <img v-for="(item,index) in applist" :key="index" :src="item">
        </div>
      </div>
    </div>
    <!--app-box-->
    <!--need-box-->
    <div class="out-box">
      <div class="need-box">
        <div class="title">
          丰富功能满足各种需求
        </div>
        <div class="subtitle">
          专为出海企业、跨境电商、媲美真机
        </div>
        <ul class="need-list">
          <li v-for="(item,index) in needlist" :key="index">
            <img :src="item.img">
            <div>{{ item.p }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!--need-box-->
    <!--phone-box-->
    <div class="out-box">
      <div class="phone-box">
        <div class="title">云手机套餐</div>
        <div class="phone-list">
          <ul>
            <li class="top-title">
              <div>功能</div>
              <div>私域流量版</div>
              <div>尊享直播版</div>
            </li>
            <li v-for="(item,index) in phonelist" :key="index">
              <div>{{ item.g }}</div>
              <div class="middle">
                <template v-if="item.v1.length === 1">
                  <img :src="require('./img/' + item.v1 + '.png')">
                </template>
                <template v-else>
                  {{ item.v1 }}
                </template>
              </div>
              <div>
                <template v-if="item.v2.length === 1">
                  <img :src="require('./img/' + item.v2 + '.png')">
                </template>
                <template v-else>
                  {{ item.v2 }}
                </template>
              </div>
            </li>
            <li class="area-box">
              <div style="text-align: center">
                <div>可用地区</div>
                <div class="buy-time">购买时长</div>
              </div>
              <div class="middle">
                <div class="area">
                  <div class="name">香港</div>
                </div>
                <div class="time-box">
                  <div class="time" v-for="(item,index) in Timelist" :key="index" :class="{'active':timeFlag1 === index}" @click="timeFlag1 = index">{{ item }}</div>
                </div>
              </div>
              <div style="padding: 0">
                <div class="area">
                  <div v-for="(item,index) in area2" :key="index" :class="{'active':area2Flag === index}" class="name" @click="area2Flag = index">{{ item }}</div>
                </div>
                <div class="time-box">
                  <div v-for="(item,index) in Timelist" :key="index" :class="{'active':timeFlag2 === index}" class="time" @click="timeFlag2 = index">{{ item }}</div>
                </div>
              </div>
            </li>
            <li class="price-box">
              <div style="font-size: 18px">价格</div>
              <div class="middle wrap">低至<span class="price">{{ hkPrice }}</span>{{ '元/月/台' }}<div class="buy" @click="yunLogin()">立刻购买</div></div>
              <div class="wrap">低至<span class="price">{{ area2price }}</span>{{ '元/月/台' }}<div class="buy" @click="yunLogin()">立刻购买</div></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <cloud-phone-footer-banner></cloud-phone-footer-banner>
  </div>
</template>

<script>
import CloudPhoneFooterBanner from '@/layout/footer/cloudphoneFooterBanner'

const scenes = [
  {
    title: '全球推广',
    p: ' 全面支持 Faceook、Tiktok、Twitter、ins、亚马逊等平台，防封防限流，各大海外平台推广无忧 ',
    img: require('./img/sce_1.png')
  },
  {
    title: '跨境电商',
    p: '  独立IP，稳定后台管理防关联；将社交和店铺矩阵相结合，提高曝光率，提升销量  ',
    img: require('./img/sce_2.png')
  },
  {
    title: '海外直播',
    p: '  虚拟摄像头功能支持开启 Tiktok直播、facebook直播、ins直播，稳定流畅  ',
    img: require('./img/sce_3.png')
  },
  {
    title: '社交电商',
    p: '  把Whatsapp，Skype，Email等社交App安装在云手机上，方便的与国外客户沟通交流  ',
    img: require('./img/sce_4.png')
  }
]
const adv = [
  {
    title: '助力出海营销',
    p: '  灵活海外IP池，一键切换/绑定，助力海外社交营销，企业海外推广无忧。  ',
    img: require('./img/adv1.png')
  },
  {
    title: '数据云安全',
    p: ' 数据云端化，防泄漏，防恶意操作与误操作，帮助用户实现云数据库的安全运维和远程数据存储。 ',
    img: require('./img/adv2.png')
  },
  {
    title: '高效群控功能',
    p: '灵活一人即可批量控制多台云手机及，操作更轻松，支持自定义镜像，快速复制云手机。',
    img: require('./img/adv3.png')
  },
  {
    title: '业务稳定',
    p: '云手机 7x24 小时长期稳定在线，零耗电，零流量，实时预览云手机界面，自动故障检测与恢复。',
    img: require('./img/adv4.png')
  }
]
const applist = [require('./img/tiktok.png'), require('./img/amazon.png'), require('./img/instagiam.png'), require('./img/facebook.png'), require('./img/twitter.png'), require('./img/wish.png')]
const needlist = [
  {
    img: require('./img/need/1.png'),
    p: '海外社媒营销'
  },
  {
    img: require('./img/need/2.png'),
    p: '直播引流带货'
  },
  {
    img: require('./img/need/3.png'),
    p: 'APP推广'
  },
  {
    img: require('./img/need/4.png'),
    p: '智能移动办公'
  },
  {
    img: require('./img/need/5.png'),
    p: '养小号'
  },
  {
    img: require('./img/need/6.png'),
    p: '电商评测'
  },
  {
    img: require('./img/need/7.png'),
    p: '全球广告投放'
  },
  {
    img: require('./img/need/8.png'),
    p: '一键新机'
  },
  {
    img: require('./img/need/9.png'),
    p: '批量上传/下载'
  }
]
const phonelist = [
  {
    g: '高效群控,矩阵操作',
    v1: 'R',
    v2: 'R'
  },
  {
    g: '自定义镜像和应用库',
    v1: 'R',
    v2: 'R'
  },
  {
    g: '全球GPS定位',
    v1: 'R',
    v2: 'R'
  },
  {
    g: '独立私域IP',
    v1: 'F',
    v2: 'R'
  },
  {
    g: '远程摄像头，多路同步直播',
    v1: 'F',
    v2: 'R'
  },
  {
    g: '定向带宽加速',
    v1: 'F',
    v2: 'R'
  },
  {
    g: '定向带宽加速',
    v1: 'F',
    v2: 'R'
  },
  {
    g: '应用场景',
    v1: '批量养号，海外社媒营销，游戏托管',
    v2: '精品养号，直播电商，大型游戏'
  }
]
const Timelist = ['1个季度', '半年', '一年']
const area2 = ['美国', '英国', '印尼']
export default {
  name: 'Cloudphone',
  components: { CloudPhoneFooterBanner },
  computed: {
    hkPrice() {
      if (this.timeFlag1 === 0) {
        return 211
      }
      if (this.timeFlag1 === 1) {
        return 194
      }
      if (this.timeFlag1 === 2) {
        return 166
      }
      return '错误'
    },
    area2price() {
      if (this.area2Flag === 0) {
        if (this.timeFlag2 === 0) return 300
        if (this.timeFlag2 === 1) return 267
        if (this.timeFlag2 === 2) return 233
      }

      if (this.area2Flag === 1) {
        if (this.timeFlag2 === 0) return 400
        if (this.timeFlag2 === 1) return 356
        if (this.timeFlag2 === 2) return 311
      }

      if (this.area2Flag === 2) {
        if (this.timeFlag2 === 0) return 400
        if (this.timeFlag2 === 1) return 356
        if (this.timeFlag2 === 2) return 311
      }

      return '错误'
    }
  },
  data() {
    return {
      scenes: scenes,
      adv: adv,
      applist: applist,
      needlist: needlist,
      phonelist: phonelist,
      Timelist: Timelist,
      area2: area2,
      timeFlag1: 0,
      area2Flag: 0,
      timeFlag2: 0
    }
  },
  methods: {
    yunLogin() {
      window.open('http://cloud.vllshop.com/portal/login', '_blank')
      // window.location.href = 'http://cloud.vllshop.com/portal/login'
    }
  }
}
</script>
<style lang="scss" scoped>
.time-box > .time:nth-of-type(1)::after{
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  content: '9.5折';
  text-align: center;
  color: #9eb1d0;
  background-color: #d5eafc;
  width: 40px;
  border-bottom-left-radius: 10px;
  height: 16.8px;
  line-height: 16px;
  font-size: 12px;
}
.time-box > .time:nth-of-type(2)::after{
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  content: '8.5折';
  text-align: center;
  color: #9eb1d0;
  background-color: #d5eafc;
  width: 40px;
  border-bottom-left-radius: 10px;
  height: 16.8px;
  line-height: 16px;
  font-size: 12px;
}
.time-box > .time:nth-of-type(3)::after{
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  content: '7.5折';
  text-align: center;
  color: #9eb1d0;
  background-color: #d5eafc;
  width: 37px;
  border-bottom-left-radius: 10px;
  height: 16.8px;
  line-height: 16px;
  font-size: 12px;
}
.time-box > .time.active:after{
  background-color: #fff;
  color: #347efb;
}
</style>
<style scoped lang="scss">
.home_text{
  text-align: left;
  div.title h2{
    font-size: 64px;
    color: #fff;

  }
  div.title h2 .org{
    color: #FFA200;
  }
  div.title p{
    color: white;
    font-size: 24px;
    margin-top: 31px;
  }
  background-color: #00307D;
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a_white{
  color: white !important;
  &:after{
    border-top-color: white !important;
    border-right-color: white !important;
  }
}
.title-word:before{
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url('/images/title_left_white.png') no-repeat;
  margin-right: 10%;
  background-size: 100%;
}
.title-word:after{
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url('/images/title_right_white.png') no-repeat;
  margin-left: 10%;
  background-size: 100%;
}
.title-word{
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 3.66rem;
  font-weight: bolder;
  text-align: center;
  vertical-align: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
img{
  border: none;
  vertical-align: top;
}
.out-box .subtitle{
  color: #110f4f;
  font-size: 16px;
  margin-top: 6px;
  text-align: center;
}
*{
  box-sizing: border-box;
}
.main .out-box{
  padding: 40px 0;
  overflow: hidden
}
.main .out-box:nth-child(odd){
  background-color: #f7f7f7;
}
</style>
<!--scenes-->
<style scoped lang="scss">
@media only screen and (min-width: 0px) and (max-width: 576px){
  .main .scenes-box .scenes-list li{
    padding: 0 !important;
  }
}
.out-box{
  >div{
    max-width: 1100px;
    margin: 0 auto;
  }
  .title{
    color: #0e0d3d;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }
  .text{
    flex: 1;
    color: #161b2c;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }
}
.main .scenes-box .scenes-list{
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
  }
  .scenes-img{
    width: 150px;
  }
  li:first-child img{
    width: 100px;
    margin-bottom: 35px;
  }
  li img{
    width: 100px;
    margin-right: 10px;
  }
  .scenes-title{
    line-height: 1.8;
    color: #0e0d3d;
    font-weight: 700;
    text-align: left;
  }
}

</style>
<!--adv-box-->
<style scoped lang="scss">

.adv-box .adv-list{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  li{
    height: 250px;
    padding: 25px 20px;
    border-radius: 2px;
    box-shadow: 0 5px 8px rgba(0,0,0,.2);
    background: #f7f7f7;
    list-style: none;
    img{
      height: 70px;
      display: block;
      margin: 0 auto 20px auto;
    }
  }
  .adv-title{
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    font-size: 16px;
    color: #0e0d3d;
  }
}
.exp-btn{
  cursor: pointer;
  border: none;
  color: #fff;
  width: 100px;
  height: 35px;
  border-radius: 3px;
  font-size: 16px;
  display: block;
  margin: 30px auto 0;
  background-image: linear-gradient(90deg,#0bb9fa,#4285ec);
  text-align: center;
}
</style>
<!--app-box-->
<style scoped lang="scss">
.app-list div{
  margin-top: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.app-list img{
  width: 170px;
}
</style>
<!--need-box-->
<style scoped lang="scss">
.need-list{
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  li{
    width: 33.33333%;
    border: 1px solid #d6deed;
    margin-top: -1px;
    margin-left: -1px;
    padding: 13px 0;
    text-align: center;
    box-sizing: border-box;
    list-style: none;
  }
  img{
    width: 60px;
    margin-bottom: 5px;
  }
  div {
    font-size: 15px;
    font-weight: 700;
    color: #161b2c;
    text-align: center;
  }
}
</style>
<!--phone-box-->
<style scoped lang="scss">
div.phone-list{
  border-radius: 3px;
  box-shadow: 0 5px 8px rgba(0,0,0,.2);
  margin-top: 15px;
  .top-title{
    border: none;
    color: #0e0d3d;
    font-size: 18px;
    background: linear-gradient(180deg,#fff,#f5f8ff,#c0d1fe);

  }
}
.phone-box{
  font-size: 18px;
  li{
    display: flex;
    text-align: center;
    font-weight: 700;
    list-style: none;
    color: #161b2c;
    border-top: 1px solid #ccc;
    >div{
      padding: 15px 0;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;
      img{
        width: 30px;
      }
    }
    .active{
      background: #347efb;
      color: #fff;
    }
    .middle {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
    .buy-time{
      margin-top: 30px;
    }
    .time-box{
      display: flex;
      .time{
        height: 57px;
        line-height: 50px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
  .time-box>div{
    margin-top: 1px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
  }
  .area-box .middle{
    padding: 0;
  }
  .price{
    font-size: 23px;
    color: #2d78f9;
  }
  .buy{
    margin-left: 8px;
    padding: 6px 15px;
    background-image: linear-gradient(90deg,#0bb9fa,#4285ec);
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }
  .wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
.price-box{
  font-size: 14px;
}
.area-box .area{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  .name{
    display: flex;
    height: 57px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<!-- footer -->
<style scoped lang="scss">
.footer{
  padding: 20px 0;
  background: #f7f7f7;
  .content{
    padding: 15px 30px;
    background: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
  }
  .connect{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .square{
    margin-right: 8px;
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #347efb;
  }
  .info-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .about{
      width: 42%;
      li{
        margin-bottom: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 15px;
        color: #000;
        list-style: none;
      }
      .company-name{
        margin-top: 10px;
        font-size: 18px;
      }
      .label{
        color: #666;
      }
      .phone{
        color: #2d78f9;
      }
    }
  }
}
.footer-right{
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  .gzh{
    color: #666;
    text-align: center;
    img{
      width: 130px;
      margin-bottom: 2px;
    }
  }
  .textarea{
    textarea{
      width: 300px;
      height: 100px;
      padding: 10px;
      display: block;
      margin-bottom: 2px;
      font-size: 16px;
    }
    button{
      width: 50px;
      display: block;
      margin-bottom: 2px;
      background: #280d02;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 2px;
      margin: 10px auto 0;
      font-size: 16px;
    }
  }
}
</style>
